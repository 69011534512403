<template>
  <div class="banner">
    <v-container>
      <v-row>
        <v-col cols="12" md="8">
          <MainBanner :info="info"/>
        </v-col>

        <v-col cols="12" md="4">
          <BestSeller :products="products"/>
          <Info class="mt-5" :publisher="publisher"/>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import MainBanner from "./Banner/Main.vue";
import BestSeller from "./Banner/BestSeller.vue";
import Info from "./Banner/Info.vue";
import axios from "axios";
export default {
  components: {
    MainBanner,
    BestSeller,
    Info,
  },
  data() {
    return {
      info: [],
      products: [],
      publisher: [],
    };
  },
  mounted() {
    this.getCarousels();
  },
  methods: {
    getCarousels() {
      axios.get("carousel").then((res) => {
        this.info = res.data.data.carousels["1"];
        this.products = res.data.data.carousels["2"];
        this.publisher = res.data.data.carousels["3"];
      });
    },
  },
};
</script>
