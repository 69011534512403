<template>
  <v-carousel height="190" hide-delimiters show-arrows-on-hover>
    <v-carousel-item v-for="(item, index) in products" :key="index">
      <v-sheet :color="colors[index]" height="100%" v-bind:style="{ backgroundImage: 'url(' + item.image + ')', backgroundSize: 'cover' }">
        <v-row class="fill-height" align="center">
          <div class="px-10 py-3">
            <div class="font-weight-bold text-caption">{{item.caption}}</div>
            <div class="text-h4 font-weight-black">{{item.title}}</div>
            <div class="row-action mt-5">
              <v-btn class="pa-0" small text color="red" dark :href="item.link" target="_blank"> Selengkapnya </v-btn>
            </div>
          </div>
        </v-row>
      </v-sheet>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
export default {
  props: ['products'],
  data() {
    return {
      colors: [
        "indigo",
        "warning",
        "pink darken-2",
        "red lighten-1",
        "deep-purple accent-4",
      ],
      texts: ["First", "Second", "Third", "Fourth", "Fifth"],
    };
  },
};
</script>
