<template>
  <div class="banner grey lighten-5">
    <v-container>
      <div class="section-title text-center">
        <div class="text-h4 font-weight-bold">Top Products</div>
        <div class="text-overline">Our Best Products</div>
      </div>

      <v-row class="fill-height" v-if="loadingData">
        <v-col cols="12" md="3" v-for="i in 4" :key="i">
          <v-skeleton-loader
            class="p-3"
            type="card, text@3, button"
          ></v-skeleton-loader>
        </v-col>
      </v-row>

      <v-row class="fill-height" align="stretch" justify="center" v-else>
        <v-col cols="12" md="3" v-for="item in items" :key="item.id" class="d-flex flex-column">
            <v-card class="mx-auto my-5 content-section flex d-flex flex-column" :to="{ name: 'show', params: { id: item.naskah.qrcbn_id } }">
              <div class="content-image">
                <v-img
                  lazy-src="https://picsum.photos/id/11/10/6"
                  :src="item.naskah.cover_url"
                ></v-img>
              </div>
              <div class="content-detail white pa-3">
                <div class="text-overline">{{ item.naskah.qrcbn_id }}</div>
                <div class="font-weight-medium text-uppercase">
                  {{ item.naskah.title }}
                </div>
                <v-rating
                  :value="4.5"
                  color="amber"
                  dense
                  half-increments
                  readonly
                  size="14"
                ></v-rating>
                <div class="text-caption font-weight-black primary--text pb-3">
                  {{ item.naskah.user.type }} {{ item.naskah.user.name }}
                </div>
                <v-divider class="mb-3" />
                <div class="text-caption text-justify">
                  {{ item.naskah.description | sinopsis }}
                </div>
                <div class="text-body-2 mt-3 primary--text">
                  {{ item.naskah.author.substring(0, 25) + "..." }}
                </div>
              </div>
            </v-card>
          </router-link>
        </v-col>
      </v-row>

      <div class="d-flex mt-3">
        <v-btn color="primary darken-3" class="mx-auto px-10" to="/shop">See More</v-btn>
      </div>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
export default {
  components: {},
  data() {
    return {
      items: [],
      loadingData: false,
    };
  },
  mounted() {
    this.getData();
  },
  filters: {
    sinopsis(value) {
      return value.split(" ").slice(0, 20).join(" ") + "...";
    },
  },
  methods: {
    getData() {
      this.loadingData = true;

      axios
        .get("topProducts", {
          headers: {
            "Access-Control-Allow-Headers": "*",
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((response) => {
          this.items = response.data.data.data;
          this.loadingData = false;
        })

        .catch((error) => {
          console.log(error);
          this.submitForm = false;
        });
    },
  },
};
</script>
<style scoped>
a {
  text-decoration: none !important;
}
</style>
