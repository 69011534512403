<template>
  <div class="home">
      <Banner/>
      <SecondBanner/>
      <BestSeller class="mt-10"/>
      <!-- <BestPublisher class="mt-10"/> -->
      <Connect class="mt-10"/>
  </div>
</template>

<script>
import Banner from '@/components/HomePage/Banner.vue'
import SecondBanner from '@/components/HomePage/SecondBanner.vue'
import BestSeller from '@/components/HomePage/BestSeller.vue'
// import BestPublisher from '@/components/HomePage/BestPublisher.vue'
import Connect from '@/components/HomePage/Connect.vue'
export default {
  name: 'Home',
  components: {
    Banner,
    SecondBanner,
    BestSeller,
    // BestPublisher,
    Connect
  }
}
</script>
