<template>
  <v-footer dark padless>
    <v-card class="flex" flat tile>
      <v-card-title class="primary">
        <strong class="font-weight-black text-overline"
          >Get connected with us on social networks!</strong
        >

        <v-spacer></v-spacer>

        <v-btn
          v-for="item in items"
          :key="item"
          class="mx-4"
          dark
          icon
          :href="item.link"
          target="_blank"
        >
          <v-icon size="24px">
            {{ item.icons }}
          </v-icon>
        </v-btn>
      </v-card-title>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  data: () => ({
    items: [
      {
        icons: "fab fa-facebook",
        link: "https://www.facebook.com/profile.php?id=100081468788230",
      },
      {
        icons: "fab fa-instagram",
        link: "https://www.instagram.com/qrcbooknumber/",
      },
      { icons: "fab fa-tiktok", link: "https://www.tiktok.com/@qrcbn" },
    ],
  }),
};
</script>
