<template>
  <div class="home">
    <v-container>
      <v-row class="fill-height" align="center" justify="center">
        <v-col cols="12" md="3">
          <v-card>
            <v-row class="fill-height" align="center">
              <v-col cols="12" md="2">
                <v-icon x-large>mdi-account-outline</v-icon>
              </v-col>
              <v-col cols="12" md="10">
                <div class="text-h6 banner-text-item font-weight-bold">
                  800+ Penerbit
                </div>
                <div class="text-body-2">
                  Temukan buku menarik dari ratusan penerbit
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>

        <v-col cols="12" md="3">
          <v-card>
            <v-row class="fill-height" align="center">
              <v-col cols="12" md="2">
                <v-icon x-large>mdi-account-outline</v-icon>
              </v-col>
              <v-col cols="12" md="10">
                <div class="text-h6 banner-text-item font-weight-medium">
                  4000+ Buku
                </div>
                <div class="text-body-2">
                  Ribuan buku sudah menggunakan QRCBN sebagai nomor identifikasi
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>

        <v-col cols="12" md="3">
          <v-card>
            <v-row class="fill-height" align="center">
              <v-col cols="12" md="2">
                <v-icon x-large>mdi-account-outline</v-icon>
              </v-col>
              <v-col cols="12" md="10">
                <div class="text-h6 banner-text-item font-weight-medium">
                  Jaringan Internasional
                </div>
                <div class="text-body-2">
                  Website resmi QRCBN dapat diakses diseluruh dunia.
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>

        <!-- <v-col cols="12" md="3">
          <v-row class="fill-height" align="center">
            <v-col cols="12" md="2">
              <v-icon x-large>mdi-account-outline</v-icon>
            </v-col>
            <v-col cols="12" md="10">
              <div class="text-h6 banner-text-item font-weight-medium">
                  Jaringan Internasional
              </div>
              <div class="text-body-2">Website resmi QRCBN dapat diakses diseluruh dunia.</div>
            </v-col>
          </v-row>
        </v-col> -->
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
};
</script>
